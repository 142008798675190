<template>
    <div class="section-general">
        <div class="section-banner" data-scroll="0" id="top">
            <div class="section-banner__content">
                <a class="logo-koikeya"><img :src="webp(require(`../../../public/img/corporat/logo-koikeya.png`))" alt="Koikeya"></a>
                <img class="full-width sp lazyload" :data-src="webp(require(`../../../public/img/corporat/banner-img-sp.jpg`))" alt="karamucho snack cay số 1 nhật bản">
                <img class="full-width pc lazyload" :data-src="webp(require(`../../../public/img/corporat/banner-img.jpg`))" alt="karamucho snack cay số 1 nhật bản">

                <div class="section-banner__wrapper">
                    <div class="content">
                        <h2 class="title">{{ $t('latestNews') }}</h2>
                        <p v-html="lastNews ? lastNews.title.rendered : ''"></p>
                    </div>
                    <div class="btn"><a :href="lastNews ? '/news/' + lastNews.slug : ''"></a></div>
                </div>
            </div>
        </div><!--/ .section-banner -->
        <div class="section-brand" data-scroll="1" id="brand-message">
            <div class="container">
                <div class="section-brand-message animation fadeup">
                    <div class="section-brand-message__title"><img :class="`lazyload ${$i18n.locale}`" :data-src="webp(require(`../../../public/img/corporat/title/brand-message-${$i18n.locale}.png`))" alt="Brand Message"></div>
                    <div class="section-brand-message-content">
                        <p>{{ $t('brandMessage') }}</p>
                        <img class="lazyload" :data-src="webp(require(`../../../public/img/corporat/signature-thankyou.png`))" alt="Thank you">
                    </div>
                    <img class="element lazyload" :data-src="webp(require(`../../../public/img/corporat/brand-message-element.png`))" alt="karamucho">
                </div><!--/ .section-brand-message -->

                <div class="mission-vision animation fadeup">
                    <div class="mission-vision__wrapper">
                        <div class="content mission">
                            <div class="mission-vision__title"><img :class="`lazyload ${$i18n.locale}`" :data-src="webp(require(`../../../public/img/corporat/title/mission-${$i18n.locale}.png`))" alt="Mission"></div>
                            <div class="mission-vision__content">{{ $t('mission') }}</div>
                        </div>
                        <div class="content vision">
                            <div class="mission-vision__title"><img :class="`lazyload ${$i18n.locale}`" :data-src="webp(require(`../../../public/img/corporat/title/vision-${$i18n.locale}.png`))" alt="Vision"></div>
                            <div class="mission-vision__content">{{ $t('vision') }}</div>
                        </div>
                    </div>
                </div><!--/ .mission-vision -->
            </div>
        </div><!--/ .section-brand -->
        <div class="company-info">
            <div class="container">
                <div class="company-info__wrapper" data-scroll="2" id="company-info">
                    <div class="company-info__image"><img class="lazyload" :data-src="webp(require(`../../../public/img/corporat/product/koikeya.jpg`))" alt="koikeya"></div>
                    <div class="company-info__content">
                        <div class="company-info__title"><img :class="`lazyload ${$i18n.locale}`" :data-src="webp(require(`../../../public/img/corporat/title/company-info-${$i18n.locale}.png`))" alt="Company Information"></div>
                        <ul class="company-info__list">
                            <li v-for="(companyInfo, infoIndex) in $t('companyInfos')" :key="`company-info-${infoIndex}`">
                                <div class="title">{{ companyInfo.title }}: </div>
                                <div class="content">{{ companyInfo.content }}</div>
                            </li>
                        </ul>
                    </div><!--/ .company-info__content -->
                </div><!--/ .company-info__wrapper -->
            </div>
        </div><!--/ .company-info -->
    </div>
</template>

<script>
    export default {
        name: 'General',
        props: {
            num: Number,
            lastNews: Object
            //postNum: null
        },
        mounted() {
            //window.addEventListener('scroll', this.navScroll)
        },
        methods: {
            navScroll() {
                const el = document.querySelector(`[nav-scroll="0"]`),
                      pos = el.getBoundingClientRect().top
                // console.log(pos)
                if (pos === 0) {
                    //this.posNUm = this.num++
                    this.$emit('postNum', this.num++)
                }
            }
        }
    }
</script>

<style lang="scss">
    @import '../../assets/scss/mixin/_mixin';

    $sm: 768;
    $md: 992;
    $lg: 1200;

    .section-general {
        padding-top: 84px;
        @include media(set-range, $md - 1, $sm) {
            padding-top: 102px;
        }
        @include media(set-min-w, $md) {
            padding-top: 114px;
            background: url("../../../public/img/corporat/bg/bg-section-general.jpg") no-repeat right 95%;
        }
        @include media(set-min-w, $lg) {
            padding-top: 0;
        }
    }

    .section-banner {
        position: relative;
        max-width: 1920px;
        margin: 0 auto;

        .full-width {
            width: 100%;

            &.sp {
                @include media(set-min-w, $sm) {
                    display: none;
                }
            }

            &.pc {
                display: none;
                @include media(set-min-w, $sm) {
                    display: block;
                }
            }
        }

        .logo-koikeya {
            position: absolute;
            top: 47px;
            left: 50%;
            transform: translateX(-50%);
            width: 78px;
            z-index: 9;
            @include media(set-range, $md - 1, $sm) {
                top: 2%;
            }
            @include media(set-range, 1599, $md) {
                top: 3%;
            }
            @include media(set-min-w, 1600) {
                top: 51px;
            }
        }

        &__content {
            position: relative;
            max-height: calc(100vh - 84px);
            max-height: -moz-calc(100vh - 84px);
            max-height: -webkit-calc(100vh - 84px);
            overflow: hidden;
            @include media(set-range, $md - 1, $sm) {
                max-height: calc(100vh - 102px);
                max-height: -moz-calc(100vh - 102px);
                max-height: -webkit-calc(100vh - 102px);
            }
            @include media(set-min-w, $lg - 1, $md) {
                max-height: calc(100vh - 114px);
                max-height: -moz-calc(100vh - 114px);
                max-height: -webkit-calc(100vh - 114px);
            }
            @include media(set-min-w, $lg) {
                max-height: 100vh;
            }


            // &::after {
            //     @include media(set-min-w, $lg) {
            //         content: '';
            //         position: absolute;
            //         right: -3.7%;
            //         bottom: 5%;
            //         width: 13%;
            //         max-width: 212px;
            //         height: 352px;
            //         background: url("../../../public/img/corporat/pie.png") no-repeat right bottom / contain;
            //     }
            // }
        }

        &__wrapper {
            position: absolute;
            right: 12px;
            bottom: 0;
            left: 0;
            display: flex;
            @include media(set-min-w, $sm) {
                right: 0;
                bottom: 0;
            }

            .content {
                padding: 10px 15px;
                min-height: 80px;
                background-color: #cd181b;
                color: #fff;
                flex-grow: 1;
                max-width: 835px;
                @include media(set-min-w, $sm) {
                    max-width: 50%;
                }
            }

            .title {
                position: relative;
                display: inline-block;
                margin-bottom: 3px;
                font-size: 14px;
                font-weight: 600;

                @include media(set-min-w, $sm) {
                    margin-bottom: 7px;
                }

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 3px;
                    background-color: #fff;
                }
            }

            p {
                max-height: 40px;
                line-height: 20px;
                font-size: 14px;
                font-weight: 600;
                text-transform: uppercase;
                overflow: hidden;

                @include media(set-min-w, $sm) {
                    padding-left: 20px;
                }
            }

            .btn {
                flex: 0 0 93px;
                max-width: 93px;
                padding-left: 8px;

                @include media(set-min-w, $md) {
                    padding-bottom: 3px;
                    padding-left: 3px;
                }

                a {
                    display: block;
                    width: 100%;
                    height: 100%;
                    background: #fff url("../../../public/img/icon/icon-arrow-half.png") no-repeat center center;
                }
            }
        }
    }

    .section-brand {
        position: relative;
        max-width: 1920px;
        margin: 0 auto;
        overflow: hidden;
        @include media(set-min-w, $lg) {
            //padding-right: 290px;
            background: {
                image: url("../../../public/img/corporat/icon-triangle-pink.png"), url("../../../public/img/corporat/icon-triangle03.png");
                repeat: no-repeat;
                position: 95% 150px, 90% 55%;
            }
        }

        &::after {
            @include media(set-min-w, $sm) {
                content: '';
                position: absolute;
                top: 74%;
                left: 50%;
                transform: translateX(-50%);
                width: 340px;
                height: 340px;
                background: url("../../../public/img/corporat/bg/bg-section-brand-after.png") no-repeat top center / contain;
            }
            @include media(set-range, $lg - 1, $md) {
                top: 60%;
                height: 400px;
            }
            @include media(set-min-w, $lg) {
                top: 50%;
                width: 473px;
                height: 541px;
                margin-left: -145px;
            }
        }

        .container {
            @include media(set-min-w, $sm) {
                display: flex;
                justify-content: flex-end;
                padding-bottom: 140px;
            }
            @include media(set-min-w, $lg) {
                padding-bottom: 280px;
            }
        }
        &.active &-message {
            &:after {
                transform: scale(1);
            }
        }
        &-message {
            position: relative;
            padding: 80px 20px 190px;
            margin-bottom: 20px;
            @include media(set-min-w, $sm) {
                flex: 0 0 50%;
                max-width: 600px;
            }
            @include media(set-min-w, $md) {
                padding-right: 3%;
            }


            &::after {
                content: '';
                position: absolute;
                right: -11%;
                bottom: 0;
                width: 1800px;
                height: 1500px;
                border-radius: 15%;
                z-index: -1;
                background: url("../../../public/img/corporat/bg/bg-section-brand.jpg") repeat left top;
                box-shadow: 0 0 21px #0000007a;
                transform: scale(0);
                transform-origin: 0 0;
                transition: transform .8s ease-in-out;

                @include media(set-min-w, $sm) {
                    right: 0;
                }
                @include media(set-min-w, $md) {
                    border-bottom-right-radius: 22%;
                }
            }

            &__title {
                margin-bottom: 20px;
                @include media(set-min-w, $sm) {
                    padding-top: 30px;
                }

                img {
                    max-height: 100px;
                    @include media(set-min-w, $sm) {
                        max-height: none;
                    }
                }
            }

            &-content {
                padding: 0 5px;
                line-height: 30px;
                color: #3b3b3b;
                font-size: 16px;
                font-weight: 600;

                p {
                    margin-bottom: 30px;
                }
            }

            .element {
                position: absolute;
                right: -50px;
                bottom: -160px;
                width: 300px;
                @include media(set-min-w, $sm) {
                    display: none;
                }
            }
        }

        .mission-vision {
            padding: 90px 30px 70px;
            background: {
                image: url("../../../public/img/corporat/icon-triangle.png"), url("../../../public/img/corporat/lines-zigzag.png");
                repeat: no-repeat;
                position: 0 0, 92% 75px;
            }
            @include media(set-min-w, $sm) {
                flex: 0 0 50%;
                background: {
                    image: url("../../../public/img/corporat/icon-triangle-red.png"), url("../../../public/img/corporat/icon-triangle02.png");
                    repeat: no-repeat;
                    position: 58% 67px, 30% 90%;
                }
            }
            @include media(set-range, $lg - 1, $md) {
                padding-left: 40px;
            }
            @include media(set-min-w, $lg) {
                padding-left: 80px;
            }

            &__title {
                margin-bottom: 20px;
            }

            &__content {
                line-height: 3.1rem;
                color: #6a6a6a;
                font-size: 1.6rem;
                font-weight: 600;
            }

            .mission {
                padding-bottom: 35px;
                margin-bottom: 15px;
                background: url("../../../public/img/corporat/bg/mission-vision-sp02.jpg") no-repeat left bottom;
                @include media(set-min-w, $sm) {
                    margin-bottom: 0;
                    background: none;
                }
            }

            .vision {
                @include media(set-min-w, $sm) {
                    padding-top: 30px;
                    background: url("../../../public/img/corporat/lines-zigzag.png") no-repeat 54% top;
                }
            }
        }
    }

    .company-info {
        position: relative;
        background: rgb(255,255,255);
        background: -moz-linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(241,241,241,1) 4%, rgba(238,238,238,1) 100%);
        background: -webkit-linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(241,241,241,1) 4%, rgba(238,238,238,1) 100%);
        background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(241,241,241,1) 4%, rgba(238,238,238,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#eeeeee",GradientType=1);

        @include media(set-min-w, $sm) {
            background: url("../../../public/img/corporat/icon-triangle-red.png") no-repeat 90px 190px;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 35px;
            width: 20px;
            height: 38px;
            background: url("../../../public/img/corporat/icon-triangle-red.png") no-repeat center top;

            @include media(set-min-w, $sm) {
                display: none;
            }
        }

        &::after {
            content: '';
            position: absolute;
            top: -25px;
            right: 0;
            width: 40px;
            height: 24px;
            background: url("../../../public/img/corporat/icon-triangle-pink.png") no-repeat center top;
            @include media(set-min-w, $sm) {
                display: none;
            }
        }

        .container {
            max-width: 1360px;
            margin: 0 auto;
        }

        &__wrapper {
            margin: -60px 20px 0;
            background-color: #fff;
            border-radius: 10px;
            box-shadow: 0 0 30px #00000061;
            overflow: hidden;
            transform: translateY(60px);

            @include media(set-min-w, $md) {
                display: flex;
                margin-top: -75px;
                transform: translateY(75px);
            }

            @media screen and (min-width: 1400px) {
                align-items: center;
            }
        }

        &__image {
            @media screen and (min-width: $md + 'px') {
                flex: 0 0 35%;
                max-width: 35%;
                @media (max-width: 1400px - 1) {
                    position: relative;
                    overflow: hidden;
                }
            }
            @include media(set-min-w, $lg) {
                flex: 0 0 45%;
                max-width: 45%;
            }

            img {
                width: 100%;
                @include media(set-range, 1399, $md) {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: auto;
                    max-width: none;
                    min-width: 100%;
                    min-height: 100%;
                }
            }
        }

        &__content {
            padding: 45px 20px 35px;
            line-height: 3rem;
            color: #000;
            font-size: 1.6rem;
            @include media(set-min-w, $md) {
                flex: 0 0 65%;
                max-width: 65%;
                padding: 20px 20px 20px 60px;
            }
            @include media(set-min-w, $lg) {
                flex: 0 0 55%;
                max-width: 55%;
            }
        }

        &__title {
            margin-bottom: 20px;
            @include media(set-min-w, $md) {
                margin-bottom: 30px;
            }

            img {
                max-height: 73px;
                @include media(set-min-w, $lg) {
                    max-height: none;
                }
            }
        }

        &__list {
            li {
                margin-bottom: 10px;
                @include media(set-min-w, $md) {
                    display: flex;
                    margin-bottom: 5px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .title {
                display: inline;
                font-weight: 700;
                @include media(set-min-w, $md) {
                    flex: 0 0 200px;
                }
            }

            .content {
                display: inline;
                @include media(set-min-w, $md) {
                    flex-grow: 1;
                }
            }
        }
    }

    .webp {
        .section-general {
            @include media(set-min-w, $md) {
                background: url("../../../public/img/corporat/bg/bg-section-general.webp") no-repeat right 95%;
            }
        }

        .section-banner {
            &__content {
                &::after {
                    @include media(set-min-w, $lg) {
                        background: url("../../../public/img/corporat/pie.webp") no-repeat right bottom / contain;
                    }
                }
            }
            &__wrapper {
                .btn {
                    a {
                        background: #fff url("../../../public/img/icon/icon-arrow-half.webp") no-repeat center center;
                    }
                }
            }
        }

        .section-brand {
            @include media(set-min-w, $lg) {
                background: {
                    image: url("../../../public/img/corporat/icon-triangle-pink.webp"), url("../../../public/img/corporat/icon-triangle03.webp");
                }
            }

            &::after {
                @include media(set-min-w, $sm) {
                    background: url("../../../public/img/corporat/bg/bg-section-brand-after.webp") no-repeat top center / contain;
                }
            }
            &-message {
                &::after {
                    background: url("../../../public/img/corporat/bg/bg-section-brand.webp") repeat left top;
                }
            }

            .mission-vision {
                background: {
                    image: url("../../../public/img/corporat/icon-triangle.webp"), url("../../../public/img/corporat/lines-zigzag.webp");
                }

                @include media(set-min-w, $sm) {
                    background: {
                        image: url("../../../public/img/corporat/icon-triangle-red.webp"), url("../../../public/img/corporat/icon-triangle02.webp");
                    }
                }

                .mission {
                    background: url("../../../public/img/corporat/bg/mission-vision-sp02.webp") no-repeat left bottom;
                }

                .vision {
                    @include media(set-min-w, $sm) {
                        background: url("../../../public/img/corporat/lines-zigzag.webp") no-repeat 54% top;
                    }
                }
            }
        }

        .company-info {
            @include media(set-min-w, $sm) {
                background: url("../../../public/img/corporat/icon-triangle-red.webp") no-repeat 90px 190px;
            }

            &::before {
                background: url("../../../public/img/corporat/icon-triangle-red.webp") no-repeat center top;
            }

            &::after {
                background: url("../../../public/img/corporat/icon-triangle-pink.webp") no-repeat center top;
            }
        }
    }

</style>